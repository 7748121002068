import React, { useEffect, useState } from 'react';
import { Layout, Menu, Input, Table, Button, Skeleton, Steps, Card, Typography, message } from 'antd';
import { SearchOutlined, MailOutlined } from '@ant-design/icons';
import { getApprovals, getapproved, getRejected } from '../../services/generic';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';


const { Sider, Content } = Layout;
const { Step } = Steps;
const { Title, Text } = Typography;

const ApprovalWorkflowUI = () => {
  const [selectedInboxItem, setSelectedInboxItem] = useState();
  const [approvlData, setApprovlData] = useState()
  const [tableColumns, setTableColumns] = useState([]);
  const [commentText, setCommentText] = useState(null)
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentStep, setCurrentStep] = useState()
  const [url, setUrl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState()
  const [searchQuery, setSearchQuery] = useState('');
  const [messageWithoutTable, setMessageWithoutTable] = useState('');
  const history = useHistory();

  const filteredApprovals = approvlData?.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  <Input
    prefix={<SearchOutlined />}
    placeholder="Search"
    value={searchQuery}
    onChange={handleSearchChange}
  />

  useEffect(() => {
    const fetchData = async () => {
      const approvals = await getApprovals();
      const approvalsData = JSON.parse(approvals); // Parse the JSON string
      const workflowDetails = approvalsData["workflow_details - "]; // Access the array with bracket notation
console.log(workflowDetails)
      // Set the data to state
      setApprovlData(workflowDetails);
      setSelectedInboxItem(workflowDetails[0])
      setSelectedMenu(workflowDetails[0]?.cwf_workflow_approvals_id)
      setLoading(false)
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (selectedInboxItem) {
      setUrl(`/popupWindow/${selectedInboxItem.window_id}/${selectedInboxItem.record_id}`);
      // Create a temporary DOM element to parse the HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = selectedInboxItem.message;

      // Extract all <strong> elements and their corresponding text values
      const strongElements = tempDiv.querySelectorAll('strong');
      const dynamicColumns = [];
      const dynamicData = {};

      Array.from(strongElements).forEach((el, index) => {
        const label = el.innerText.replace(':', '').trim();
        const value = el.nextSibling?.nodeValue?.trim() || '';

        // Add each label as a dynamic column
        dynamicColumns.push({
          title: label,
          dataIndex: `column${index}`,
          key: `column${index}`,
        });

        // Map each value to its corresponding dynamic column
        dynamicData[`column${index}`] = value;
      });

      setTableColumns(dynamicColumns);
      setTableData([dynamicData]); // Set the table data with the extracted values

      // Replace <br> tags with newlines
      const messageWithBreaks = tempDiv.innerHTML.replace(/<br\s*\/?>/gi, '\n');
      const desiredPart = messageWithBreaks.split('<div><strong>')[0].replace(/\n/g, '<br/>');
      setMessageWithoutTable(desiredPart);

      const approvalState = selectedInboxItem?.approval_state ? JSON.parse(selectedInboxItem.approval_state) : {};

      // Ensure that approvalState is always an object
      const {
        previousState = '',
        currentState = '',
        nextState = ''
      } = approvalState || {};

      const steps = [
        previousState,
        currentState,
        nextState
      ].filter(Boolean); // Remove any null, undefined, or empty string values

      const currentStepIndex = steps.indexOf(currentState);

      setCurrentStep(currentStepIndex)
      // console.log(desiredPart);
      // Remove unwanted HTML elements like tables and strong
      // Array.from(tempDiv.querySelectorAll('table, strong')).forEach(el => el.remove());

      // // Replace remaining HTML tags with empty string and preserve newlines
      // const messageWithoutTable = tempDiv.innerHTML
      //   .replace(/<\/?[^>]+>/gi, '') // Remove remaining HTML tags
      //   .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      //   .trim(); // Trim leading and trailing spaces

      // // Preserve line breaks
      // const finalMessage = messageWithoutTable.trim();

      // // Set the cleaned text content
      // console.log(finalMessage);
    }
  }, [selectedInboxItem, approvlData]);






  const handleMenuClick = ({ key }) => {
    setLoading(true);
    const selectedItem = approvlData.find(item => item.cwf_workflow_approvals_id === key);
    setSelectedInboxItem(selectedItem);
    setSelectedMenu(key)
    setTimeout(() => setLoading(false), 500); // Simulate loading time
  };

  const renderSkeleton = () => (
    <Card style={{ padding: "1%" }}>
      <Skeleton active title={false} paragraph={{ rows: 1, width: '50%' }} />
      <Skeleton.Input style={{ width: '70%', marginTop: 16 }} active />
      <div style={{ padding: '2% 2% 0 2%', fontSize: '12px', fontWeight: 400 }}>
        <Skeleton paragraph={{ rows: 3 }} active />
      </div>
      <Skeleton.Button style={{ width: 120, marginTop: 16 }} active />
      <Skeleton.Button style={{ width: 120, marginLeft: 8, marginTop: 16 }} active />
      <Skeleton.Button style={{ width: 160, marginLeft: 8, marginTop: 16 }} active />
      <Skeleton.Input style={{ width: '45%', marginTop: 24 }} active />
    </Card>
  );

  const onApprove = async () => {
    setLoading(true)
    let approved = await getapproved(selectedInboxItem.cwf_workflow_approvals_id,commentText);
    console.log(approved)
    if (approved.messageCode === '200') {
      message.success(approved.message)
      const approvals = await getApprovals();
      setApprovlData(JSON.parse(approvals))
      setSelectedInboxItem(JSON.parse(approvals)[0])
      setSelectedMenu(JSON.parse(approvals)[0]?.cwf_workflow_approvals_id)
      setLoading(false)
    } else {
      setLoading(false)
      message.error(approved.message)
    }
  }

  const onReject = async () => {
    setLoading(true)
    let rejected = await getRejected(selectedInboxItem.cwf_workflow_approvals_id,commentText);
    console.log(rejected)
    if (rejected.messageCode === '200') {
      message.success(rejected.message)
      const approvals = await getApprovals();
      setApprovlData(JSON.parse(approvals))
      setSelectedInboxItem(JSON.parse(approvals)[0])
      setSelectedMenu(JSON.parse(approvals)[0]?.cwf_workflow_approvals_id)
      setLoading(false)
    } else {
      setLoading(false)
      message.error(rejected.message)
    }
  }

  return (
    <Layout style={{ minHeight: '100vh', background: "#f3f4f5" }}>
      <Sider width={250} theme="light">
        <div style={{ padding: '16px' }}>
          <Title level={4}>Inbox</Title>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <Menu
          mode="inline"
          selectedKeys={selectedMenu}
          onClick={handleMenuClick}
        >
          {filteredApprovals?.map(item => (
            <Menu.Item
              style={{ height: "60px", padding: "10px 20px" }}
              key={item.cwf_workflow_approvals_id}
            >
              <span>{item.title}</span>
              {/* <span style={{ fontSize: '12px', color: '#999' }}>{item.initiated_at}</span> */}
              <span style={{ display: "flex", justifyContent: "space-between", marginBottom: '2px', lineHeight: "20px" }}>

                <span style={{ fontSize: '12px', color: '#999', marginTop: '2px' }}>{item.initiated_by}</span>
                <span style={{ fontSize: '12px', color: '#999' }}>{item.initiated_at}</span>
              </span>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ padding: '0 0 10px 10px', backgroundColor: '#f3f4f5', fontFamily: "Inter" }}>
          {loading ? renderSkeleton() : (
            selectedInboxItem && (
              <Card style={{ padding: "1% 2%" }}>
                <Title level={4} style={{ fontSize: "20px", fontWeight: 600, marginBottom: 0 }}>
                  {selectedInboxItem.type === "Approval"
                    ? `Approval Required: ${selectedInboxItem.title}`
                    : selectedInboxItem.title}
                </Title>
                {/* <Text type="secondary" style={{fontSize:"11px",fontWeight:400}}>
                Nick Magovac to AS, Pranav, Santhosh, Dileep, Abraar, Vasanth, Vinutha, Avinash, Vishal, Santosh, Shaik
              </Text> */}
                {/* <div style={{ padding:"2%",fontSize:"12px",fontWeight:400 }}>
                <Text>Dear [Recipient's Name],</Text>
                <br />
                <br/>
                <Text style={{marginTop:"3vh"}}>
                  This is an automated notification requesting your approval for a new sales quotation. Below are the details:
                </Text>
              </div> */}
                {/* <Table style={{ width:"70%" }} size='small' columns={columns} dataSource={tabData} pagination={false} /> */}
                <div style={{ padding: '2%', fontSize: '12px', fontWeight: 400 }}>
                  <div dangerouslySetInnerHTML={{ __html: messageWithoutTable }} />
                </div>
                {tableColumns.length > 0 && 
                (<Table
                  style={{ width: "70%",paddingLeft:"2%" }}
                  size='small'
                  columns={tableColumns}
                  dataSource={tableData}
                  pagination={false}
                />)}
                <Link to={url} style={{ textDecoration: 'none' }}>
                  <a
                    href={url}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      window.open(url, "Record View", "width=1000,height=700,left=210,top=120");
                    }}
                    style={{ padding: 0, margin: '1px 30% 0 0', float: 'right', display: 'inline-block' }}
                  >
                    View Document
                  </a>
                </Link>
                {/* <br />
                <br/> */}
                {selectedInboxItem.type === "Approval" ?
                  <>
                    <div style={{ margin: '22px 0',paddingLeft:"2%" }}>
                      <Text strong>Action Required:</Text> Please review and approve the document at your earliest convenience.
                    </div>
                    <Steps size='small' current={currentStep} style={{ marginTop: '30px', marginBottom: '35px', padding: "0 10% 0 2%" }}>
                      {JSON.parse(selectedInboxItem.approval_state).previousState ? <Step title={JSON.parse(selectedInboxItem.approval_state).previousState} /> : null}
                      <Step title={JSON.parse(selectedInboxItem.approval_state).currentState} />
                      <Step title={JSON.parse(selectedInboxItem.approval_state).nextState} />
                    </Steps>
                    <div style={{ marginBottom: '24px',paddingLeft:"2%" }}>
                      <Button onClick={onApprove} style={{ marginRight: '8px', borderRadius: "4px", fontWeight: 700, background: "#0C173A", color: "#fff" }}>Approve</Button>
                      <Button onClick={onReject} style={{ marginRight: '8px', borderRadius: "4px", fontWeight: 700, background: "#BB1010", color: "#fff" }}>Reject</Button>
                      {/* <Button style={{ marginRight: '8px', borderRadius: "4px", fontWeight: 700, background: "#F4AC21", color: "#fff" }}>Propose Review</Button> */}
                    </div>
                    <span style={{ fontSize: "13px",paddingLeft:"2%" }}>
                      Remarks (If Any)
                    </span>
                    <br />
                    <Input.TextArea onChange={(e) => { setCommentText(e.target.value) }} value={commentText} style={{ width: "45%",marginLeft:"2%" }} rows={4} />
                    <br /></> : null}
                {/* <div style={{fontSize:"12px"}}>
                <Text>Thank you for your cooperation.</Text>
                <br />
                <Text>Best regards,</Text>
                <br />
                <Text>CW Suite</Text>
              </div> */}
              </Card>
            )
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default ApprovalWorkflowUI;