import React, { useState, useEffect } from "react";
import { Card, Button, Tabs, Select, Typography, Checkbox, Row, Col, Form, Spin, message } from "antd";
import { getTabData } from "../../../services/generic";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";

const { TabPane } = Tabs;

const WindowComponent = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [applications, setApplications] = useState([]);
  const [selectedAppIndex, setSelectedAppIndex] = useState(0);
  const [menuStructure, setMenuStructure] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [modifiedPermissions, setModifiedPermissions] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("0"); // Add state for active tab

  const genericUrl = process.env.REACT_APP_genericUrl;

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getTabData({
          windowId: "7349",
          ad_tab_id: "F189888FEEB6406AA22E0752CD7B7656",
          startRow: "0",
          endRow: "50",
        });

        setRoles(response || []);
        if (response?.[0]) {
          setSelectedRole(response[0]["3AAD9D715B3441F0B69205F1300FDE7B"]);
          setSelectedRoleId(response[0]["BF7571952CB244008C3519C4B9F96CD2"]);
        }
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchApplications = async () => {
      if (!selectedRoleId) return;

      try {
        setLoading(true);
        const { access_token } = getOAuthHeaders();
        const appId = localStorage.getItem("appId");

        const response = await Axios.post(
          genericUrl,
          {
            query: `
              query {
                getTableData(
                  tableName: "cs_menu_schema",
                  columns: "app_id,app_name",
                  whereClause: "cs_menu_schema_id in (select cs_menu_schema_id from cs_role_application where cs_role_id = '${selectedRoleId}')",
                  orderByClause: null,
                  limit: 10000
                ) {
                  data
                }
              }
            `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              appId,
            },
          }
        );

        const apps = JSON.parse(response?.data?.data?.getTableData?.data || "[]");
        setApplications(apps);
      } catch (error) {
        console.error("Error fetching applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [selectedRoleId]);

  useEffect(() => {
    const fetchMenuAndPermissions = async () => {
      const currentApp = applications[selectedAppIndex];
      if (!currentApp?.app_id || !selectedRoleId) return;

      try {
        setLoading(true);
        const { access_token } = getOAuthHeaders();
        const appId = localStorage.getItem("appId");

        const [menuResponse, permissionsResponse] = await Promise.all([
          Axios.post(
            genericUrl,
            {
              query: `query { getRoleAccessMenu(applicationId: "${currentApp.app_id}") }`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
                appId,
              },
            }
          ),
          Axios.post(
            genericUrl,
            {
              query: `query { getRolePermissions(roleId:"${selectedRoleId}", applicationId: "${currentApp.app_id}") }`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
                appId,
              },
            }
          ),
        ]);

        const parsedMenu = JSON.parse(menuResponse?.data?.data?.getRoleAccessMenu || "[]");
        const parsedPermissions = JSON.parse(permissionsResponse?.data?.data?.getRolePermissions || "[]");

        setMenuStructure(parsedMenu[0]?.children || []);
        setRolePermissions(parsedPermissions);
        setActiveTabKey("0"); // Reset to first tab when menu structure changes
        setModifiedPermissions(new Set());
      } catch (error) {
        console.error("Error fetching menu and permissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuAndPermissions();
  }, [applications, selectedAppIndex, selectedRoleId]);

  const handlePermissionChange = (menuId, permission, checked) => {
    setRolePermissions((prev) => {
      const updatedPermissions = [...prev];
      const menuIndex = updatedPermissions.findIndex((p) => p.recordId === menuId);

      if (menuIndex !== -1) {
        updatedPermissions[menuIndex] = {
          ...updatedPermissions[menuIndex],
          [permission.toLowerCase()]: checked ? "Y" : "N",
        };
      }

      return updatedPermissions;
    });

    setModifiedPermissions((prev) => {
      const newSet = new Set(prev);
      newSet.add(menuId);
      return newSet;
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { access_token } = getOAuthHeaders();
      const appId = localStorage.getItem("appId");

      const changedPermissions = rolePermissions.filter((permission) => modifiedPermissions.has(permission.recordId));

      const formattedPermissions = changedPermissions.map((permission) => ({
        record_id: permission.recordId,
        module_id: permission.moduleId,
        isview: permission.view,
        iswrite: permission.write,
        isdelete: permission.delete,
        isdownload: permission.download,
        name: permission.name,
        module_name: permission.module,
        type: permission.type,
        access_id: permission.accessId,
        tableName: "AccessLines",
      }));

      const response = await Axios.post(
        genericUrl,
        {
          query: `
            mutation {
              upsertRolePermissions(
                roleId: "${selectedRoleId}",
                roleJson: ${JSON.stringify(JSON.stringify(formattedPermissions))}
              ) {
                title
                message
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
            appId,
          },
        }
      );
      if (response.data?.data?.upsertRolePermissions?.title === "Success") {
        setModifiedPermissions(new Set());
        message.success(response.data?.data?.upsertRolePermissions?.message);
      }
    } catch (error) {
      console.error("Error saving permissions:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderPermissionRow = (menu) => {
    const permissions = ["View", "Write", "Delete", "Download"];
    const currentPermissions = rolePermissions.find((p) => p.recordId === menu.id) || {};

    return (
      <Row
        key={menu.id}
        align="middle"
        style={{
          marginBottom: "10px",
          padding: "10px 0",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Col span={6}>{menu.title}</Col>
        <Col span={18}>
          <Row gutter={16}>
            {permissions.map((permission) => (
              <Col key={permission} span={6} style={{ textAlign: "center" }}>
                <Checkbox checked={currentPermissions[permission.toLowerCase()] === "Y"} onChange={(e) => handlePermissionChange(menu.id, permission, e.target.checked)} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      <div style={{ height: "83vh", display: "flex", flexDirection: "column" }}>
        <Row align="middle" justify="space-between">
          <Typography.Title level={3}>Manage Role Permissions</Typography.Title>
          <div>
            <Button
              type="primary"
              onClick={handleSave}
              style={{
                marginRight: "10px",
                borderRadius: "4px",
                fontFamily: "Inter",
                fontWeight: 700,
              }}
            >
              Save
            </Button>
            <Button
              style={{
                borderRadius: "4px",
                fontFamily: "Inter",
                fontWeight: 700,
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
        <Scrollbars
          // style={{
          //   height: "75vh",
          //   width: "100%",
          // }}
          hidden={false}
          hideTracksWhenNotNeeded={true}
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
          <Card bordered={false}>
            <Form layout="vertical" style={{ marginBottom: "20px" }}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={<Typography.Text strong>Role</Typography.Text>}>
                    <Select
                      value={selectedRole}
                      onChange={(value) => {
                        const role = roles.find((r) => r["3AAD9D715B3441F0B69205F1300FDE7B"] === value);
                        setSelectedRole(value);
                        setSelectedRoleId(role["BF7571952CB244008C3519C4B9F96CD2"]);
                      }}
                    >
                      {roles.map((role, index) => (
                        <Select.Option key={index} value={role["3AAD9D715B3441F0B69205F1300FDE7B"]}>
                          {role["3AAD9D715B3441F0B69205F1300FDE7B"]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={<Typography.Text strong>Application</Typography.Text>}>
                    <Select
                      value={applications[selectedAppIndex]?.app_id}
                      onChange={(value) => {
                        const index = applications.findIndex((app) => app.app_id === value);
                        setSelectedAppIndex(index);
                      }}
                    >
                      {applications.map((app, index) => (
                        <Select.Option key={index} value={app.app_id}>
                          {app.app_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {menuStructure.length > 0 && (
              <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                {menuStructure.map((section, index) => (
                  <TabPane tab={section.title} key={index.toString()}>
                    {section.children?.length > 0 ? (
                      <>
                        <Row style={{ fontWeight: "bold", marginBottom: "10px" }}>
                          <Col span={6}>Menu</Col>
                          <Col span={18}>
                            <Row gutter={16} justify="center">
                              {["View", "Write", "Delete", "Download"].map((header) => (
                                <Col key={header} span={6} style={{ textAlign: "center" }}>
                                  {header}
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                        {section.children.map((menu) => renderPermissionRow(menu))}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "20px", color: "#888" }}>No menus available for this tab.</div>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            )}
          </Card>
        </Scrollbars>
      </div>
    </Spin>
  );
};

export default WindowComponent;
